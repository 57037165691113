import axios from 'axios';
import { HOST_URL } from './host';
import { format } from "date-fns";
import logger from 'redux/middlewares/logger';
export const RETRIEVE_VIRTUAL_CARDS = "RETRIEVE_VIRTUAL_CARDS";
export const SET_CARD_USERS_CARDS = "SET_CARD_USERS_CARDS";
export const SET_CARD_USERS = "SET_CARD_USERS";
export const SET_CARD_USER = "SET_CARD_USER";
export const SET_CARDS = "SET_CARDS";

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-Accept-Version':'2.0'
};


export async function handleGetVirtualCard({token, id}, callback)
{
    return axios
    .get(`${HOST_URL}/api/action/card/${id}`,{
        headers: {
            ...headers,
            'Authorization': `Bearer ${token}`,
        } 
    })
    .then((res)=>{
            if(res.data.success){ 
                callback({type:'success', data: res.data.data, message: res.data.message})
            }else{
                callback({type:'danger', message:res.data.message})
            }
    })
    .catch(err=>console.error(err));

} 
  function setCards(cards) {
    return {
      type: SET_CARDS,
      payload: cards,
    };
  }
  function setCardUser(cardUser) {
    return {
      type: SET_CARD_USER,
      payload: cardUser,
    };
  }
  function setCardUsers(cardUsers) {
    return {
      type: SET_CARD_USERS,
      payload: cardUsers,
    };
  }
  function setCardUsersCards(cardUsersCards) {
    return {
      type: SET_CARD_USERS_CARDS,
      payload: cardUsersCards,
    };
  }
  
 
export function handleNewCard(
  { token, balance, currency, category, card_type, reference, grade },
  callback
  ) {
  return async () => {
    try {
      const res = await axios.post(
        `${HOST_URL}/api/action/card/${reference}`,
        {
          balance,
          currency,
          category,
          card_type,
          grade
        },
        {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        if (res.data.success) {
          callback({ type: true, message: res.data.message });
        } else {
          callback({ type: false, message: res.data.message });
        }
      }
    } catch (err) {
      console.error(err);
      callback({ type: false, message: err.response.data.message });
    }
  };
}

  export function handleNewCustomer(
    {
      token,
      first_name,
      last_name,
      email,
      country_code,
      contact,
      dob,
      is_bussiness,
      business_name,
      billing_city,
      billing_address,
      billing_country,
      billing_state,
      billing_postal_code,
      id_number,
    },
    callback
  ) {
    const formattedStartDate = format(new Date(dob), "yyyy-MM-dd'T'HH:mm:ssxxx");
    return axios
        .post(
          `${HOST_URL}/api/action/card/user`,
          {
            first_name,
            last_name,
            email,
            country_code,
            contact,
            dob:formattedStartDate,
            is_bussiness,
            business_name,
            billing_address,
            billing_city,
            billing_country,
            billing_state,
            billing_postal_code,
            id_number,
          },
          {
            headers: {
              ...headers,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success) {
              callback({
                type: true,
                message: res.data.message,
                data: res.data.data,
              });
            } else {
              callback({ type: false, message: res.data.message });
            }
          }
        }) 
        .catch((err) => console.error(err));
      }
  
  // export function VerifyUser(
  //   {
  //     token,
  //     email,
  //     first_name,
  //     last_name,
  //     country_code,
  //     contact,
  //     dob,
  //     is_bussiness,
  //     business_name,
  //     billing_address,
  //     billing_country,
  //     billing_state,
  //     billing_postal_code,
  //     id_number,
  //   },
  //   callback
  // ) {
  //   return async (dispatch) => {
  //     try {
  //       await dispatch(
  //         handleSearchUser({ token, email }, async (userData) => {
  //           if (!userData) {
  //             // Si le tableau userData est vide, cela signifie que l'utilisateur n'existe pas encore
  //             await dispatch(
  //               handleNewUser(
  //                 {
  //                   token,
  //                   first_name,
  //                   last_name,
  //                   email,
  //                   country_code,
  //                   contact,
  //                   dob,
  //                   is_bussiness,
  //                   business_name,
  //                   billing_address,
  //                   billing_country,
  //                   billing_state,
  //                   billing_postal_code,
  //                   id_number,
  //                 },
  //                 (newUserData) => {
  //                   if (newUserData.success) {
  //                     // Mettre les informations du nouvel utilisateur dans la store
  //                     dispatch(setUserCard(newUserData.data));
  //                     callback(newUserData);
  //                   } else {
  //                     callback(newUserData);
  //                   }
  //                 }
  //               )
  //             );
  //           } else {
  //             // Si l'utilisateur existe déjà, mettre ses informations dans la store
  //             dispatch(setUserCard(userData));
  //             callback({ success: true, data: userData });
  //             // console.log("Utilisateur trouvé : ", userData);
  //           }
  //         })
  //       );
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };
  // }
  
  
  export function handleGetUsers({ token }) {
    return async (dispatch) => {
      return axios
        .get(`${HOST_URL}/api/action/card/user`, {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            dispatch(setCardUsers(res.data.data.users));
          }
        })
        .catch((err) => console.error(err));
    };
  }
  
  export function handleGetCards({ token, email }) {
    return async (dispatch) => {
      return axios
        .get(`${HOST_URL}/api/action/card/user?email=${email}`, {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            
            dispatch(setCards(res.data.data.cards));
          }
        })
        .catch((err) => console.error(err));
    };
  }
  
  export function handleGetCardUser({ token, reference }, callback) {
    return axios
      .get(`${HOST_URL}/api/action/card/user/${reference}`, {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          callback(res.data.data);
          return res.data.data;
        }
      })
      .catch((err) => console.error(err));
 
  }
  
  export function handleGetUser({ token, email }) {
    return async (dispatch) => {
      return axios
        .get(`${HOST_URL}/api/action/card/user?email=${email}`, {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            dispatch(setCardUser(res.data.data.users[0]));
            dispatch(setCards(res.data.data.cards));
          }
        })
        .catch((err) => console.error(err));
    };
  }
  
  export function handleSearchUser({ token, email }, callback) {
    return async () => {
      try {
        const res = await axios.get(
          `${HOST_URL}/api/action/card/user?email=${email}`,
          {
            headers: {
              ...headers,
              Authorization: `Bearer ${token}`,
            },
          }
        );
        callback( res.data.data.users[0]);
       
      } catch (err) {
        console.error(err);
      }
    };
  }
  
  export async function handleCardAction(
    { token, id, operation, amount, currency },
    callback
  ) {
      try {
        return axios
        .get(
          `${HOST_URL}/api/action/card/transaction/${id}?action=${operation}&amount=${amount}&currency=${currency}`,
          {
            headers: {
              ...headers,
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              callback({ success: response.data.success, message: response.data.message });
            }
          }) 
          .catch((err) => {
            console.error(err);
          })
      } catch (error) {
        callback({ success: false, message: error.response.data.message });
      }
    };
  
  export function handleGetCurrencyCurrentAmount(
    { from, to, line, token, apiKey, type, amount },
    callback
  ) {
    return async () => {
      return axios
        .get(
          `${HOST_URL}/api/user/get-out-value?from=${from}&to=${to}&line=${line}&type=${type}&amount=${amount}`,
          {
            headers: {
              ...headers,
              Authorization:` Bearer ${token}`,
              "x-api-key": `${apiKey}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success) callback(`${res.data.data.amount} ${res.data.data.devise}`);
          }
        })
        .catch((err) => {
          console.error(err);
          console.error(err.response.data.message);
        });
    };
  }
  
  export function handleGetCardFees({ token }, callback) {
    return async () => {
      try {
        const res = await axios.get(`${HOST_URL}/api/action/card/fees`, {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (res.status === 200) {
          if (res.data.success) {
            callback(res.data.data.fees);
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
  }
  
  export function handleGetCardExternalAmount(
    { from, to, token, amount, brand, grade, type },
    callback
  ) {
    return async () => {
      return axios
        .get(
          `${HOST_URL}/api/user/get-out-value?from=${from}&to=${to}&amount=${amount}&brand=${brand}&grade=${grade}&type=${type}`,
          {
            headers: {
              ...headers,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success);
            callback(res.data.data);
          }
        })
        .catch((err) => {
          console.error(err);
          console.error(err.response.data.message);
        });
    };
  }
  
  export function handleExternalDepositCard(
    {
      token,
      in_wallet,
      out_wallet,
      in_amount,
      out_amount,
      otp,
      service,
    },
    callback
  ) {
    return async () => {
      try {
        const res = await axios.post(
          `${HOST_URL}/api/user/changes`,
          {
            in_amount,
            out_amount,
            in_wallet,
            out_wallet,
          },
          {
            headers: {
              ...headers,
              Authorization: `Bearer ${token}`,
              operation: "10",
              inService: `${service}`,
              outService: "16",
              otp: `${otp}`,
            },
            withCredentials: true,
          }
        );
        if (res.status === 200 && res.data.success) {
          if ([11, 3, 10].includes(service) || [7, 8, 25, 32].includes(service)) {
            callback({ success: true, message: res.data.data });
          } else {
            callback({ success: true, message: res.data.message });
          }
        } else {
          callback({ success: false, message: res.data.message });
        }
      } catch (err) {
        console.error(err);
        callback({ success: true, message: err.response.data.message });
      }
    };
  }
  
 

